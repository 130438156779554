<template>
    <div class="a-detail">
        <el-card  class="box-card">
            <div slot="header" class="clearfix">
                <span>实验室能力</span>
            </div>
            <el-table
                    :data="[detail]"
                    border
                    stripe
                    style="width: 100%">
                <el-table-column
                    v-for="(col, index) in tableColumn"
                    :key="index"
                    :label="col.label"
                    :show-overflow-tooltip="['test_description', 'detection_items'].includes(col.prop)"
                    :prop="col.prop"
                    :width="col.width"
                >

                </el-table-column>
                <el-table-column
                    width="260"
                    label="相关文档资料"
                >
                <div>
                  <el-button type="warning" @click="goRead" size="small">预览标准</el-button>
                  <el-button type="danger"  size="small" @click="down('fileInfo')">下载标准及相关文档</el-button>
                </div>
                  <div style="color: red;font-size: 12px">
                    <div>1.本站所有标准均来自公开发布的资源，仅供研究学习使用，请勿用于商业用途。</div>
                    <div>2.若无意中侵犯您的权利，请联系我们，我们将及时删除相关资源。</div>
                  </div>
<!--                    <el-button type="danger"  size="small" @click="down('documentation')">下载相关文档</el-button>-->
                </el-table-column>
    <!--            <el-table-column
                    prop="title"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="content"
                >
                    <template slot-scope="scope">
                        <el-link
                                size="mini"
                                @click="login" v-if="scope.row.type == 'login_btn'">{{scope.row.content}}</el-link>
                        <span v-if="scope.row.type == 'text'">{{scope.row.content}}</span>
                        <span v-if="scope.row.type == 'tag'">
                             <el-tag :type="tag.type" v-for="(tag, index) in scope.row.content" :key="index" style="margin-right: 5px">
                                {{tag.text}}
                            </el-tag>
                        </span>
                    </template>
                </el-table-column>-->
            </el-table>
            <el-row class="collect">
                <el-col :span="24">
                    <i @click="setCollect" class="iconfont  icon-jushoucanggift" v-if="isCollect"></i>
                    <i @click="setCollect" class="iconfont icon-shoucang" v-else ></i>

                </el-col>
              <el-col :span="24" >

                <span class="coll-num" style="color: black">收藏实验室能力</span>
              </el-col>
                <el-col :span="24" v-if="collCount > 0">
                    <span class="coll-num" >{{collCount}}人已收藏</span>
                </el-col>
            </el-row>
<!--            <el-row class="collect btns">-->
<!--                <el-col :span="24">-->
<!--                    <el-button type="warning" @click="goRead" size="small">预览标准</el-button>-->
<!--&lt;!&ndash;                    <el-button type="warning" @click="goRead" size="small" v-if="pay_type.includes('PR')">付费阅读</el-button>&ndash;&gt;-->
<!--&lt;!&ndash;                    <el-button type="danger"  size="small" v-if="pay_type.includes('FD')">免费下载</el-button>&ndash;&gt;-->
<!--                    <el-button type="danger"  size="small" @click="down">下载标准</el-button>-->
<!--                </el-col>-->
<!--            </el-row>-->
<!--            <el-row c>-->
<!--                <el-col :span="12">-->

<!--                </el-col>-->
<!--                <el-col :span="12">-->

<!--                </el-col>-->
<!--            </el-row>-->
        </el-card>
        <el-row class="channel_row">
            <el-col :span="12">
                <el-card  class="box-card left-card" :body-style="{position: 'relative'}">
                    <div slot="header" class="clearfix">
                        <span>实验室信息</span>
                    </div>
                    <h2>
                      {{detail.institution_name}}
                      <el-button @click="goDetailLaboratory(detail.cid)" size="mini" v-if="Object.keys(detail).length && detail.is_ident === '1'">查看详情</el-button>
                    </h2>
           <!--         <div class="description">
                      {{detail.description}}
                    </div>
                    <div>
                      <el-tag :type="tag.type" v-for="(tag, index) in $formatQuality(detail.c_qualifications)" :key="index" style="margin-right: 5px">
                        {{tag.text}}
                      </el-tag>
                    </div>-->
                  <div class="ident" v-if="detail.is_ident === '1'">
                    <img src="@/assets/images/yinzhang.png"/>
                  </div>
                    <el-descriptions class="margin-top" :labelStyle="{
                      width: '120px'
                    }"  :column="1" border>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user"></i>
                                机构联系人
                            </template>
                          <span v-if="detail.isLogin">  {{detail.institution_contact}}</span>
                          <span v-else>
                              <el-button
                                  type="text"
                                  @click="login" >登录后可查看</el-button>
                          </span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-mobile-phone"></i>
                                电话号码
                            </template>
                          <span v-if="detail.isLogin">  {{detail.institution_phone}}</span>
                          <span v-else>
                              <el-button
                                  type="text"
                              @click="login" >登录后可查看</el-button>
                          </span>

                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-location-outline"></i>
                                地址
                            </template>
                            {{detail.institution_address}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-warning-outline"></i>
                                机构简介
                            </template>
                            <div v-html="detail.institution_introduce"></div>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-warning-outline"></i>
                                资质
                            </template>
                            <div v-if="detail.isLogin">
                                <el-tag :type="tag.type" v-for="(tag, index) in $formatQuality(detail.c_qualifications)" :key="index" style="margin-right: 5px">
                                    {{tag.text}}
                                </el-tag>
                            </div>
                          <span v-else>
                              <el-button
                                  type="text"
                                  @click="login" >登录后可查看</el-button>
                          </span>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-card>
            </el-col>
<!--          <el-col :span="12">-->
<!--            <el-card  class="box-card center-card">-->
<!--              <div slot="header" class="clearfix">-->
<!--                <span>推荐实验室列表</span>-->
<!--              </div>-->
<!--              <div class="bottom_list" v-for="(item, index) in recommendList_1" @click="goDetailLaboratory(item.id)">-->
<!--                <span>{{item.name}}</span>-->
<!--                <div class="address">{{item.address}}</div>-->
<!--              </div>-->
<!--            </el-card>-->
<!--          </el-col>-->
          <el-col :span="12">
            <el-card  class="box-card right-card">
              <div slot="header" class="clearfix">
                <span>这些实验室也在做这条实验</span>
                <span style="float: right; ">
                    <el-button style="padding: 1px" :key="index" :style="{color: activeBtn === item.id ? '#409EFF' : '#606266'  }"
                               type="text"
                               @click="clickOrderBtn(item.id)"
                               v-for="(item, index) in orderBtns"
                               :class="{ active: 'active' }">
                      {{item.text}}
                    </el-button>
                </span>
<!--                <el-button style="float: right; padding: 3px 0" type="text" >操作按钮</el-button>-->
              </div>
<!--              <div class="bottom_list" :key="index" v-for="(item, index) in recommendList_2.list" @click="goDetail(item)">-->
<!--                <span><span class="list-index" :class="`hot_${index}`">{{index + 1}}</span>{{item.institution_name}}/{{item.detection_items}}/{{item.detection_standard}}</span>-->
<!--                <div class="address">{{item.province}}/{{item.city}}</div>-->
<!--              </div>-->
<!--              tableColumnRecommend-->
              <el-table
                  :data="recommendList_2.list"
                  border
                  @cell-click="goDetail"
                  stripe
                  style="width: 100%">
<!--                :show-overflow-tooltip="['test_description', 'detection_items'].includes(col.prop)"-->
                <el-table-column
                    v-for="(col, index) in tableColumnRecommend"
                    :key="index"
                    :label="col.label"
                    :prop="col.prop"
                    :width="col.width"
                >
                  <template slot-scope="scope" >
                    <span v-if="col.prop === 'qualifications'">
                      <span>{{$formatQuality(scope.row[col.prop]).map(it => it.text).join(',')}}</span>
                    </span>
                    <span v-else>{{scope.row[col.prop]}}</span>
                  </template>
                </el-table-column>
              </el-table>
                <el-pagination
                        v-if="recommendList_2.list.length"
                        style="text-align: center"
                        background
                        layout="prev, pager, next"
                        :current-page="recommendList_2.current_page"
                        :page-size="Number(recommendList_2.per_page)"
                        :page-count="recommendList_2.last_page"
                        @current-change="currentChange"
                        :total="recommendList_2.total">
                </el-pagination>
            </el-card>
          </el-col>
        </el-row>
    </div>
</template>

<script>
    import { archivesService } from '../../api/archives'
    import { channelService } from '../../api/channel'
    import {getPointData} from "@/utils/storageUtils";
    import {getPointAllData} from "../../utils/storageUtils";
    export default {
        name: "detail-index",
        data() {
            return {
                detail: {},
                collCount: 0,
                pay_type: '',
                isCollect: false,
                aid: '',
                recommendList_1: [],
                recommendList_2Page: 1,
                recommendList_2: {
                    current_page: 1,
                    isRecommend: false,
                    last_page: 1,
                    list: [],
                    per_page: 1,
                    total: 0
                },
                tableColumn: [
                    {
                        prop: 'detection_obj',
                        label: '检测对象',
                        width: '120',
                    },
                    {
                        prop: 'detection_standard',
                        label: '检测标准(方法)',
                        width: '',
                    },
                    {
                        prop: 'detection_items',
                        label: '检测项目',
                        width: '',
                    },
                    {
                        prop: 'institution_description',
                        label: '限制说明',
                        width: '',
                    },

                    // {
                    //     prop: 'city',
                    //     label: '所在城市',
                    //     width: '110',
                    // },
                    // {
                    //     prop: 'institution_name',
                    //     label: '机构名称',
                    //     // width: '100',
                    // },
                    // {
                    //   prop: 'institution_contact',
                    //   label: '机构联系人',
                    //   // width: '100',
                    // },
                    // {
                    //     prop: 'institution_phone',
                    //     label: '机构联系电话',
                    //     // width: '100',
                    // },
                    // {
                    //     prop: 'qualifications',
                    //     label: '资质',
                    //     // width: '200',
                    // },
                ],
                orderBtns: [
                  // '智能排序', '距离排序'
                  {
                    id: 1,
                    text: '智能排序'
                  },
                  {
                    id: 2,
                    text: '距离排序'
                  }
                ],
                activeBtn: 1,
              tableColumnRecommend: [
                {
                  prop: 'institution_name',
                  label: '实验室名',
                  // width: '120',
                },
                {
                  prop: 'address',
                  label: '地址',
                  width: '',
                },
                {
                  prop: 'detection_instructions',
                  label: '限制说明',
                  width: '',
                },
                {
                    prop: 'qualifications',
                    label: '资质',
                    // width: '200',
                },
              ],
            }
        },
        methods: {
            clickOrderBtn(id) {
              this.activeBtn = id
              this.getRecommend_2List(id === 2)
            },
            currentChange(page) {
                this.recommendList_2Page = page
                this.getRecommend_2List()
            },
            initMap() {
              const center  = [this.detail.longitude, this.detail.latitude]
              const map = new AMap.Map('map', {
                resizeEnable: true,
                zoom:14,
                center
              });
              // 构造点标记
              var marker = new AMap.Marker({
                icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
                position: center
              });
              map.add(marker);
              var info = [];
              info.push("<div class='input-card content-window-card'><div></div> ");
              info.push(`<div style="padding:7px 0 0 0;"><h4>${this.detail.institution_name}</h4>`);
              info.push(`<p class='input-item'>电话 : ${this.detail.institution_phone}   联系人 : ${this.detail.institution_contact}</p>`);
              info.push(`<p class='input-item'>地址 :${this.detail.address}</p></div></div>`);

              const infoWindow = new AMap.InfoWindow({
                content: info.join("")  //使用默认信息窗体框样式，显示信息内容
              });

              infoWindow.open(map, map.getCenter());
            },
            down(downType) {
                this.$pay.show({
                    id: this.aid,
                    type: 'down',
                    downType
                })
            },
            login() {
                this.$login.show()
            },
            setCollect() {
             const api = this.isCollect? archivesService.removeCollect: archivesService.collection
             api({
                  aid: this.aid,
                  title: this.detail.title,
                  id: this.aid
              }).then(res => {
                  // // console.log('res', res)
                 if (res.code == 1) {
                     this.$message.success(res.msg)
                     this.$store.dispatch('getCollList')
                     this.getCollCount()
                 } else {
                     this.$message.error(res.msg)
                 }

              })
          },
            goDetail(item) {
              // console.log(item)
                this.$router.replace({
                    query: {
                        id: item.id
                    },
                    params: {
                      search: this.$route.params.search
                    },
                    name: 'detail'
                })
            },
            goDetailLaboratory(id) {
                // // console.log('item', item)
                this.$router.push({
                    query: {
                        id
                    },
                    name: 'detailLaboratory'
                })
            },
          goRead() {
             this.$router.push({
                 name: 'detailRead',
                 query: {
                     id: this.aid
                 }
             })
          },
          getCollCount(isInit) {
              archivesService.collectCount({
                id: this.aid
              }).then(res => {
                // console.log(res);
                this.collCount = res.data
                if (!isInit) {
                    this.isCollect = !this.isCollect
                }
              })
          },
          getDetail() {
            this.aid = this.$route.query.id
            const routeParams = this.$route.params
            // console.log('routeParams', routeParams)
            if (!this.aid) {
                  this.$message.error('获取数据失败')
                  return
            }
            this.getCollCount(true)
            const pointData = getPointAllData()
            const params = { id: this.aid, views: true, keyword: routeParams.search, city: ''}
            if (pointData && pointData.addressComponent){
              //
              const { addressComponent } = pointData

              params.city = `${addressComponent.province}${addressComponent.city}`
            }
            archivesService.detail(params).then(res => {
              // // console.log('res', res)
                const data = res.data

                this.pay_type = data.pay_type
                this.isCollect = data.isCollect
                this.detail = data
                this.detail.qualifications = this.$formatQuality(data.qualifications || [].map(it => it.text).join(','));
                this.$store.dispatch('getRecordList')
                // this.initMap()
                // channelService.recommend({aid: this.aid, cid: data.cid}).then(res => {
                //   // console.log('res', res)
                //   this.recommendList_1 = res.data
                // })
                this.getRecommend_2List()
            })

          },
          getRecommend_2List(isPoint) {

              const data = this.detail
              const params = {
                  page: this.recommendList_2Page,
                  aid: data.id,
                  // latitude: point.lat,
                  // longitude: point.lng,
                  isRecommend: true,
                  detection_obj: data.detection_obj,
                  detection_items: data.detection_items,
                  standard_code: data.standard_code,
              }
              if (isPoint) {
                const point = getPointData()
                params.latitude = point.lat
                params.longitude = point.lng
              }
              archivesService.list(params).then(res => {
                  // console.log('res', res)
                  this.recommendList_2 = res.data
              })
          }
        },
        created() {
            this.getDetail()

        },
        watch: {
            $route: {
                deep: true,
                handler(val) {
                    this.getDetail()
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            // // console.log(3333)
            next()
        },
        mounted() {
          // console.log(this.$route)
        }
    }
</script>
<style  lang="scss">
  .a-detail{
    .el-card__body{
      padding: 10px 20px 20px 20px;
    }
    .custom-input-card{
      width: 18rem;
    }

    .custom-input-card .btn:last-child{
      margin-left: 1rem;
    }

    .content-window-card{
      position: relative;
      width: 250px;
      /*padding: 0.75rem 0 0 1.25rem;*/
      box-shadow: none;
      bottom: 0;
      left: 0;
      h4{
        margin: 5px 0;
      }
    }

    .content-window-card p{
      /*height: 2rem;*/
      margin: 5px 0;
      font-size: 14px;
      color: $font_2;
    }
    .channel_row{
      height: 350px;
      .el-col{
        height: 100%;
        .list-index{
          display: inline-block;
          background: 0 0;
          color: #9195a3;
          width: 18px;
          font-size: 15px;
          letter-spacing: -1px;

        }
        .address{
          font-size: 12px;
          color: $font_2;
        }
        .hot_0{
          color: #fe2d46;
        }
        .hot_1{
          color: #f60;
        }
        .hot_2{
          color: #faa90e;
        }
        .bottom_title {
          font-weight: 400;
          font-size: 16px;
          font-family: Microsoft YaHei;
          margin-bottom: 10px;
          color: #626675;
        }
        .bottom_list {
          cursor: pointer;
          font-weight: 400;
          color: #222;
          font-size: 15px;
          padding: 4px 0;
          font-family: Microsoft YaHei;
        }
      }

    }
  }
</style>
<style scoped lang="scss">
    .a-detail{
        width: 85%;
        /*height: 100%;*/
        margin: 0 auto;
        /*.el-table{*/
        /*    min-height:490px;*/
        /*}*/
      .center-card, .right-card{
        margin: 5px 0 0 5px;
        /*height: 300px;*/
      }
        .left-card{
            margin-top: 5px;
          //position: relative;
          h3{
            //margin: unset;
            display: flex;
            align-items: center;
            margin: 5px 0;
            .el-button{
              margin-left: 3px;
            }
          }
          .description{
            margin: 5px 0;
            min-height: 1px;
            color: $font_1;
          }
          #map{
            width: 100%;
            margin: 10px 0;
            height: 330px;
          }
        }
        .box-card{
            height: 100%;
            overflow: auto;
          .ident {
            position: absolute;
            top: 35%;
            left: 38%;
            opacity: 0.5;
            z-index: 10;

            img {
              width: 100px;
            }
          }
            .collect{
                margin-top: 20px;
                text-align: center;
                .coll-num{
                    font-size: 12px;
                    color: $font_3;
                }
                i{
                    cursor: pointer;
                    font-size: 30px;
                }
            }

        }
    }
</style>
